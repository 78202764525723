@import 'rc-slider/assets/index.css';

.rc-slider.range-input {
  .rc-slider-rail {
    @apply bg-silver;
  }

  .rc-slider-track,
  .rc-slider-tracks {
    @apply bg-active;
  }

  .rc-slider-handle {
    @apply bg-white border-2 border-solid border-active opacity-100;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px var(--activeDisableColor);
    @apply text-active border-active;
  }

  .rc-slider-handle:focus-visible {
    @apply border-active;
    box-shadow: 0 0 0 3px var(--activeDisableColor);
  }

  .rc-slider-handle-click-focused:focus {
    @apply border-active;
  }

  .rc-slider-handle:hover {
    @apply border-active;
  }

  .rc-slider-handle:active {
    @apply text-active;
    box-shadow: 0 0 5px var(--activeDisableColor);
  }
}
