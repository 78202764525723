ul.component__pagination {
  @apply flex
        flex-row
        items-center
        w-fit
        gap-xs;

  li {
    button {
      @apply transition-all
            w-[28px]
            h-[28px]
            flex
            items-center
            justify-center
            rounded-full
            disabled:opacity-50;
    }

    button.active-page {
      @apply text-primary
            font-bold
            bg-active
            text-white
            shadow-md;
    }
  }
}

// Target devices that can hover
@media (hover: hover) {
  ul.component__pagination li button:not(:disabled):not(.active-page) {
    @apply hover:bg-secondary
          hover:shadow-md
          hover:text-active;
  }
}
