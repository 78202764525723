.comp__user-avatar {
  @apply shadow
        border-white
        rounded-full
        font-bold
        flex
        justify-center
        overflow-hidden
        whitespace-nowrap
        items-center
        flex-none;
}

.comp__user-avatar[data-user-size='default'] {
  @apply w-avatar-sm
        h-avatar-sm
        text-sm
        border-2;
}

.comp__user-avatar[data-user-size='big'] {
  width: 56px;
  height: 56px;
  border-width: 3px;
  @apply text-2xl;
}

.comp__user-avatar[data-user-type='customer'] {
  @apply bg-white text-primary;
}

.comp__user-avatar[data-user-type='discount-customer'] {
  @apply bg-secondary text-primary;
}

.comp__user-avatar[data-user-type='teampartner'] {
  @apply bg-primary text-white;
}

.comp__user-avatar[data-user-type='prospect'] {
  @apply bg-lightGray text-primary border-lightGray;
}

.comp__user-avatar[data-user-type='external-user'] {
  @apply bg-silver text-black;
}

.comp__user-avatar-skeleton {
  @apply shadow-md
        rounded-full
        flex
        justify-center
        overflow-hidden
        whitespace-nowrap
        items-center
        flex-none
        bg-lightGray;
}
