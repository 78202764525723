button.component__search-button {
  @apply rounded-full h-full p-xs;
}

@media (hover: hover) {
  button.component__search-button {
    @apply transition-all;

    &:hover {
      @apply bg-active text-white;
    }
  }
}
