div.component__account-child-navigation-group {
  @apply flex
        flex-col
        md:grid
        md:grid-cols-2
        lg:grid-cols-3
        md:gap-sm
        mt-sm;
}

h2.component__account-child-navigation-group {
  @apply text-primary
        mt-md
        md:mt-lg;
}
