table.firstline-table.readonly thead tr {
  th {
    @apply text-silver border-b border-lightGray font-normal;
  }
}

table.firstline-table {
  @apply w-full relative border-collapse;

  thead {
    tr {
      th {
        @apply text-primary font-bold uppercase px-xs py-xxs border-b-2 border-secondary;
        font-size: 12px;
        line-height: 18px;
      }

      .select-wrapper {
        @apply inline-flex items-center relative border border-primary py-xxs pl-xs pr-sm truncate;
        width: 120px;
        line-height: 18px;

        .select-label {
          @apply text-ellipsis overflow-hidden;
        }
        .select-label:after {
          @apply border-4 border-transparent border-t-primary absolute top-1/2 right-2 -translate-y-0.5;
          content: '';
        }

        select {
          @apply absolute w-full h-full inset-0 appearance-none cursor-pointer opacity-0;
        }
      }

      // FIXME: :has() is not supported in Firefox
      .select-wrapper:has(select:disabled) {
        @apply border-0 p-0;

        .select-label {
          @apply w-full;
        }

        .select-label::after {
          @apply border-0;
        }

        select {
          @apply hidden;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        @apply px-xs py-xxs;
      }

      td > .user-profile {
        @apply flex justify-start items-center space-x-2;
      }
    }

    tr:first-of-type td {
      @apply pt-sm;
    }

    tr:nth-child(even) {
      background-color: #f6f6f7;
    }
  }
}

.btn-container {
  @apply flex flex-col items-end mb-sm;

  .save-btn {
    @apply text-primary hover:bg-secondary w-fit px-sm mt-sm;
  }
}
