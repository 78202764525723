.payouts-value-toogle-button {
  @apply p-xs -m-xs text-primary;
}

// Target devices that can hover
@media (hover: hover) {
  .payouts-value-toogle-button {
    @apply transition-all ease-in-out duration-200;
  }

  .payouts-value-toogle-button:hover {
    @apply text-active bg-secondary rounded;
  }
}

@media (hover: none) {
  .payouts-value-toogle-button {
    @apply text-active;
  }
}
