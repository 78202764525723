div.component__widget {
  @apply bg-white
        rounded-md
        shadow-md
        overflow-clip
        p-xs
        md:p-sm
        relative
        flex
        flex-col
        justify-between
        gap-xs
        h-full;

  h4.widget-title {
    @apply text-silver
          uppercase
          flex
          flex-row
          items-center
          flex-nowrap
          overflow-hidden
          line-clamp-1
          text-ellipsis
          gap-xs;
  }

  h4.widget-title[title] {
    @apply cursor-help;
  }

  div.widget-content-wrapper {
    @apply flex-grow;
  }

  div.widget-details-button {
    @apply flex flex-row justify-end;
  }
}
