.ticket {
  @apply relative transition-all;

  &::before,
  &::after {
    @apply bg-body;

    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    z-index: 2px;
  }

  &::before {
    box-shadow: inset -7.5px 0 7.5px rgba(0, 0, 0, 0.05);
    left: -7.5px;
  }

  &::after {
    box-shadow: inset 7.5px 0 7.5px rgba(0, 0, 0, 0.05);
    right: -7.5px;
  }

  .inactive-bar {
    @apply h-0
          overflow-hidden
          bg-silver
          text-lightGray
          absolute
          top-0
          right-0
          left-0
          z-1
          uppercase
          align-middle
          text-center
          font-bold
          rounded-md;

    transition: height 0.3s ease-in-out;

    &.show {
      @apply h-6;
    }
  }
}
