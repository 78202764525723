@import 'variables';
@import 'tailwind';
@import 'https://cdn.pm-international.com/assets-shared-frontend/fonts/uni-neue.css';

@layer base {
  html,
  body {
    @apply text-base font-app font-normal m-0 p-0 bg-body text-gray;
  }

  html {
    min-height: calc(100% + env(safe-area-inset-top));
  }

  div#root {
    @apply h-full;
  }
}

@layer components {
  .pmi-safe-area-inset-b {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .pmi-safe-area-inset-t {
    padding-top: env(safe-area-inset-top);
  }
  .pmi-safe-area-inset-r {
    padding-right: env(safe-area-inset-right);
  }
  .pmi-safe-area-inset-l {
    padding-left: env(safe-area-inset-left);
  }

  .pmi-force-transition-none {
    transition: none !important;
  }

  .pmi-glass-black {
    background: rgba(0, 0, 0, 0.48);
    -webkit-backdrop-filter: blur(10x);
    backdrop-filter: blur(10px);
  }

  .pmi-glass-white {
    background: rgba(255, 255, 255, 0.48);
    -webkit-backdrop-filter: blur(20x);
    backdrop-filter: blur(20px);
  }

  .pmi-caption {
    font-size: 12px;
    line-height: 18px;
    min-height: 18px;
  }

  input[type='text'] {
    font-size: 16px;
    line-height: 24px;
  }

  h1 {
    font-family: var(--pmi-font-family-headings);
    font-weight: var(--pmi-font-weigth-headings);
    font-size: 24px;
    line-height: 36px;
  }

  h2 {
    font-family: var(--pmi-font-family-headings);
    font-weight: var(--pmi-font-weigth-headings);
    font-size: 16px;
    line-height: 24px;
  }

  h3 {
    font-family: var(--pmi-font-family-headings);
    font-weight: var(--pmi-font-weigth-headings);
    font-size: 14px;
    line-height: 20px;
  }

  h4 {
    font-family: var(--pmi-font-family-headings);
    font-weight: var(--pmi-font-weigth-headings);
    font-size: 12px;
    line-height: 18px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    min-height: 24px;
  }

  small {
    font-size: 14px;
    line-height: 20px;
    min-height: 20px;
  }
}
