:root {
  --modal-overlay-fill: rgba(0, 0, 0, 0.48);
  --modal-overlay-blur: 20px;
  --modal-max-width: 800px;
  --modal-background: white;
  --modal-padding-inline: 16px;
  --modal-padding-bottom: 16px;
  --modal-padding-top: 64px;
  --modal-border-radius: 25px;
}

.modal {
  @apply overflow-auto fixed top-0 left-0 w-full h-full flex flex-col justify-start items-center z-30;
  background: var(--modal-overlay-fill);
  backdrop-filter: blur(var(--modal-overlay-blur));
}

.modal-overlay {
  @apply w-full flex flex-col relative box-border z-1 pointer-events-none;
  max-width: var(--modal-max-width);
  background: var(--modal-background);
  padding-left: var(--modal-padding-inline);
  padding-right: var(--modal-padding-inline);
  padding-top: var(--modal-padding-top);
  padding-bottom: var(--modal-padding-bottom);
  clip-path: inset(
    var(--modal-padding-top) var(--modal-padding-inline)
      var(--modal-padding-bottom) var(--modal-padding-inline) round
      var(--modal-border-radius)
  );
}

.modal-content-wrapper {
  @apply visible
        pointer-events-auto
        order-2;

  .modal-header-container {
    @apply relative;

    button.modal-close-btn {
      @apply text-silver
            bg-lightGray
            rounded-full
            p-xxs
            transition-all
            absolute
            top-sm md:top-md
            right-sm md:right-md;
    }
  }

  .modal-content-container {
    @apply p-sm md:p-md;
  }
}

@media (hover: hover) {
  .modal-content-wrapper .modal-header-container button.modal-close-btn:hover {
    @apply bg-silver text-white shadow-md;
  }
}
