.signout-button {
  @apply flex
          flex-row
          items-center
          gap-xs
          w-full
          border
          border-transparent
          text-error
          p-xs;
}

// Target devices that can hover
@media (hover: hover) {
  .signout-button {
    @apply flex
            flex-row items-center
            gap-xs
            w-full
            border
            border-transparent
            text-error
            p-xs;
  }
}
