article.component__whats-new-post {
  @apply grid
        grid-cols-1
        md:grid-cols-[250px_minmax(100px,_1fr)]
        gap-x-sm;

  header {
    @apply flex
          flex-col
          relative
          pl-0
          md:pl-md
          mt-0
          md:mt-sm;
    min-width: 250px;

    span {
      @apply text-slate-500;
    }

    h2 {
      @apply text-xl
            font-bold;
    }

    ul {
      @apply flex
            flex-row
            flex-wrap
            gap-xs
            mt-xs;

      li {
        @apply uppercase
             text-slate-500
              bg-lightGray
              rounded-md
              w-fit
              p-xs
              shadow-md;
        font-size: 10px;
        line-height: 10px;
      }
    }

    img {
      @apply mb-xs;
    }
  }

  header::before {
    @apply rounded-full
          hidden
          md:block
          absolute;
    background-color: var(--primary);
    border: 4px solid #ebebeb;
    box-shadow: 0 0 0 4px #fff;
    content: '';
    height: 16px;
    width: 16px;
    left: 1px;
    top: 6px;
  }

  img {
    @apply w-full
          object-cover
          object-center
          overflow-hidden
          rounded-md
          bg-white
          shadow-md;
    max-height: 350px;
  }

  header img {
    @apply block
          md:hidden;
  }

  div {
    @apply space-y-xs md:space-y-sm;
  }

  div img {
    @apply hidden
          md:block;
  }

  div ul {
    @apply list-disc
          list-inside;

    a {
      @apply text-primary;
    }
  }
}

// Target devices that can hover
@media (hover: hover) {
  article.component__whats-new-post div ul a {
    @apply hover:underline
          hover:text-active;
  }
}

// Target devices that cannot hover
@media (hover: none) {
  article.component__whats-new-post div ul a {
    @apply underline;
  }
}
