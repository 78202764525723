.downline-item--selected {
  @apply border-primary
        border-2
        bg-secondary;
  border-style: solid !important;
}

.downline-item {
  @apply shadow-md
          transition-all
          w-downline
          bg-white
          flex
          flex-row
          items-center
          justify-between
          rounded-md;
  margin: 2px;
}

.downline-item .downline-item--user-button {
  @apply p-xs
        flex
        flex-row
        justify-between
        items-center
        grow;
}

.downline-item--selected {
  @apply border-primary
        border-2
        bg-secondary;
  border-style: solid !important;
}

.downline-item .downline-item--expand-button {
  @apply transition-all
        text-primary
        p-sm;
}

// Target devices that can hover
@media (hover: hover) {
  .downline-item .downline-item--user-button:hover {
    @apply text-active
          bg-secondary;
  }

  .downline-item .downline-item--expand-button:hover {
    @apply text-active
          bg-secondary
          rounded;
  }
}
