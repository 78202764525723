button.commitment-action {
  @apply flex
        flex-row
        gap-sm
        items-center
        w-fit
        px-sm
        py-xs
        rounded-full
        transition-all
        border
        border-primary
        text-primary;
}

button.commitment-action:disabled {
  @apply cursor-not-allowed opacity-50;
}

@media (hover: hover) {
  button.commitment-action:hover:not(:disabled) {
    @apply bg-secondary text-active border border-active;
  }
}

@media (hover: none) {
  button.commitment-action {
    @apply border border-primary text-primary rounded-full;
  }
}
