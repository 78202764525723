button.component__notifications-bell {
  @apply relative rounded-full h-full p-xs;
}

@media (hover: hover) {
  button.component__notifications-bell {
    @apply transition-all;

    &:hover {
      @apply bg-active text-white;
    }
  }
}

//
// Unread notifications
//
button.component__notifications-bell.bell-on {
  animation-name: component__notifications-bell-shake;
  animation-duration: 500s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:after {
    @apply absolute
          rounded-full
          bg-red-600;
    height: 8px;
    width: 8px;
    top: 8px;
    right: 8px;
    content: '';
  }
}

@keyframes component__notifications-bell-shake {
  0% {
    transform: rotate(0deg);
  }
  0.01% {
    transform: rotate(-15deg);
  }
  0.02% {
    transform: rotate(0deg);
  }
  0.03% {
    transform: rotate(15deg);
  }
  0.04% {
    transform: rotate(0deg);
  }
  0.05% {
    transform: rotate(-15deg);
  }
  0.06% {
    transform: rotate(0deg);
  }
  0.07% {
    transform: rotate(15deg);
  }
  0.08% {
    transform: rotate(0deg);
  }
  0.09% {
    transform: rotate(15deg);
  }
  0.10% {
    transform: rotate(0deg);
  }
}
