.complib-tabgroup--tab {
  @apply relative
        mr-md
        last:mr-0
        ui-selected:text-active
        ui-selected:after:content-empty
        ui-selected:after:absolute
        ui-selected:after:-bottom-1
        ui-selected:after:left-0
        ui-selected:after:w-14
        ui-selected:after:h-0.5
        ui-selected:after:bg-active
        ui-selected:after:rounded-full;
}

.complib-tabgroup--panel {
  @apply mt-lg;
}
