.downline-back-button {
  @apply flex flex-row items-center mt-xs text-primary;
}

// Target devices that can hover
@media (hover: hover) {
  .downline-back-button {
    @apply hover:text-active;
  }
}

@media (hover: none) {
  .downline-back-button {
    @apply text-primary underline;
  }
}
