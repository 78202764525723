a.component__pii-badge {
  @apply w-fit
        rounded-full
        px-sm
        py-xs
        text-[10px]
        leading-[10px]
        uppercase
        bg-secondary
        text-active
        font-bold
        flex
        flex-row
        items-center
        gap-xs
        justify-center
        animate-pulse;

  span.component__pii-badge--title {
    @apply overflow-hidden
          text-ellipsis
          whitespace-nowrap
          hidden
          md:inline-block;
  }
}

// Target devices that can hover
@media (hover: hover) {
  a.component__pii-badge {
    @apply hover:bg-secondary
          hover:brightness-110;
  }
}
