// Target devices that can hover
@media (hover: hover) {
  .tracking-link {
    @apply hover:text-active hover:underline;
  }
}

// Target deices that cannot hover
@media (hover: none) {
  .tracking-link {
    @apply text-active underline;
  }
}
