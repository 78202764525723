svg.progress-widget {
  .progress-widget--foreground {
    /* This is the length of the stroke */
    stroke-dasharray: 277.47;

    /* Setting the offset to the length of the dashes, the shape is not drawn */
    stroke-dashoffset: 277.47;
  }

  .progress-widget--foreground-animation {
    /* Animate the transiton */
    transition: 1s stroke-dashoffset ease-in-out;
  }
}
