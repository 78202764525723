.downline-group {
  @apply relative
          justify-center
          flex
          flex-wrap mb-sm;

  &::before {
    // - vertical line (both mobile and desktop)
    @apply content-empty
            absolute;
    height: 16px;
    top: -16px;
    border-left: 2px solid var(--silver);
    // The border has 2px, so we remove half here to ensure it is perfectly centered
    left: calc(50% - 1px);
  }

  @apply first-of-type:before:border-none;
}
