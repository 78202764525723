:root {
  --borderButton: 5rem;
  --lightGray: #eeeeef;
  --lightFont: 300;
  --normalFont: 400;
  --boldFont: 600;
  --success: #0f9a65;
  --info: #1d4582;
  --warn: #bf6203;
  --error: #cd0039;
  --errorBg: #fbe8e5;
  --silver: #a7a9ac;
  --disableColor: #c8c8c8;
}

[data-pmiweb-theme='pm'] {
  --gray: #061423;
  --primary: #004b8d;
  --secondary: #cae3f6;
  --bodyBg: #f5f5f5;
  --pmi-font-family-headings: 'uni-neue', sans-serif;
  --pmi-font-family-body: 'uni-neue', sans-serif;
  --pmi-font-weigth-headings: var(--pmi-font-weigth-normal);
  --activeColor: #0a84ff;
  --activeDisableColor: #71bdff;
}

[data-pmiweb-theme='fitline'] {
  --gray: #090909;
  --primary: #cd0039;
  --secondary: #eee;
  --bodyBg: #f5f5f5;
  --pmi-font-family-headings: 'clan-pro', sans-serif;
  --pmi-font-family-body: 'helvetica-neue', sans-serif;
  --pmi-font-weigth-headings: var(--pmi-font-weigth-bold);
  --activeColor: #fe0a45;
  --activeDisableColor: #fe7193;
}
