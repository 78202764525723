.payoutswidget-redirect-button {
  @apply flex flex-row items-center gap-xs text-primary;
}

// Target devices that can hover
@media (hover: hover) {
  .payoutswidget-redirect-button:hover {
    @apply text-active underline;
  }
}

@media (hover: none) {
  .payoutswidget-redirect-button {
    @apply text-primary underline;
  }
}
