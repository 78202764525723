table.documents-table {
  @apply w-full border-collapse table-auto;

  thead {
    tr {
      th {
        @apply px-xs sm:px-sm border-b border-lightGray;
      }

      th p {
        @apply uppercase text-silver font-normal;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  tbody {
    tr {
      td {
        @apply px-xs sm:px-sm py-sm;
      }
    }

    tr:first-of-type td {
      @apply pt-sm;
    }

    tr:nth-child(even) {
      background-color: #f6f6f7;
    }
  }
}
