button.VirtualWarehouseHelpModal__button {
  @apply flex
        flex-row
        items-center
        gap-xs
        flex-nowrap
        w-fit
        text-active
        rounded-full
        border
        border-transparent
        px-sm
        py-xxs;
}

// For devices supporting hover effect
@media (hover: hover) {
  button.VirtualWarehouseHelpModal__button {
    @apply transition-all;

    &:hover {
      @apply bg-white
            shadow-md;
    }
  }
}

@media (hover: none) {
  button.VirtualWarehouseHelpModal__button {
    @apply underline;
  }
}
