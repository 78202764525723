h1.component__whats-new-title {
  @apply font-bold
        text-5xl;

  span {
    @apply bg-clip-text
        text-transparent
        bg-gradient-to-r
        from-activeDisabled
        to-active;
  }
}
