div.component__notification-item {
  @apply flex
        flex-row
        items-start
        gap-sm
        p-sm;

  div.notification-image {
    width: 40px;
    height: 40px;

    @apply rounded-lg
          shadow-md
          bg-lightGray
          flex-shrink-0
          bg-cover
          bg-no-repeat
          bg-center;

    img {
      @apply rounded-lg;
    }
  }

  .notification-title {
    @apply line-clamp-1;
  }

  .notification-description {
    @apply line-clamp-3;
    font-size: 14px;
  }

  .notification-timestamp {
    @apply text-silver;
    font-size: 12px;
    line-height: 18px;
    min-height: 18px;
  }
}

div.component__notification-item[data-is-read='false'] {
  @apply text-active;

  .notification-title {
    @apply font-bold;
  }
}
