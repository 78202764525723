div.component__notifications-panel {
  @apply relative
        inset-0
        z-10;

  .overlay {
    @apply fixed
          inset-0;
  }

  .panel {
    @apply bg-white
          fixed
          top-0
          right-0
          h-full
          w-full
          shadow-md
          overflow-auto
          rounded-l-md;
    max-width: 430px;

    header {
      @apply flex
            flex-row
            items-center
            justify-between
            m-sm
            mt-md;

      h2 {
        @apply font-bold
              text-primary;
        font-size: 20px;
      }

      // Close button
      button.close-btn {
        @apply text-silver
              p-xs
              -m-xs;
      }
    }

    // Mark all as read button
    button.mark-all-as-read-btn {
      @apply p-xs
            ml-xs;
      font-size: 12px;
      line-height: 18px;
      min-height: 18px;
    }

    ul {
      @apply mt-sm;
    }
  }
}

@media (hover: hover) {
  // Close button
  div.component__notifications-panel .panel header button.close-btn {
    &:hover {
      @apply bg-lightGray
            rounded-md;
    }
  }

  // Mark all as read button
  div.component__notifications-panel .panel button.mark-all-as-read-btn {
    &:hover {
      @apply bg-secondary
            text-active
            rounded-md;
    }
  }

  // Notification item
  // FIXME: :has() is not supported in Firefox
  div.component__notifications-panel .panel ul li:has(a .notification) {
    &:hover {
      @apply bg-lightGray;
    }
  }
}
