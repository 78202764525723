#comp__user-selection-form {
  @apply w-full
        flex
        flex-col
        items-start
        justify-start;

  label.radio-option {
    @apply select-none
          p-sm
          rounded-md
          cursor-pointer
          w-full
          transition-all
          border
          border-transparent;

    input[type='radio'] {
      @apply hidden;
    }
  }

  label.radio-option.selected {
    @apply bg-secondary
          border-active;
  }
}

@media (hover: hover) {
  #comp__user-selection-form label.radio-option:hover:not(.selected) {
    @apply border-transparent
          bg-secondary;
  }
}
