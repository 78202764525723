.analytics-filters-fieldset {
  @apply mb-sm;

  fieldset {
    @apply mb-sm;
  }

  legend {
    @apply mb-xs;
    font-size: 16px;
    line-height: 24px;
  }

  legend,
  label {
    @apply text-sm;
  }

  input[type='text'] {
    @apply relative w-full rounded-md px-sm py-xs border border-silver line-clamp-1 text-ellipsis;
  }

  div[data-type='number'] {
    @apply relative w-full rounded-md px-sm py-xs border border-silver;

    input[type='number'] {
      @apply text-ellipsis w-full;
    }

    &::after {
      @apply text-silver absolute right-sm;
      content: 'Ⓟ';
    }
  }

  input[type='radio'] {
    @apply absolute opacity-0 cursor-pointer;
  }

  input[type='radio'] + span {
    @apply rounded-md px-sm py-1 border border-silver inline-block w-fit mr-xs mb-xs cursor-pointer;
    font-size: 14px;
  }

  input[type='radio']:checked + span {
    @apply text-active border-active bg-active text-white;
  }

  &.inline-radios fieldset {
    legend {
      @apply float-left line-clamp-1 text-ellipsis;
    }

    label {
      @apply float-right;
    }
  }

  .select-wrapper {
    @apply border
          border-secondary
          rounded-md
          flex
          flex-row
          items-center
          p-xs
          relative
          gap-xs
          line-clamp-1
          text-ellipsis;

    select {
      @apply absolute opacity-0 w-full h-full cursor-pointer;
    }
  }
}
