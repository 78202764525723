ul.pmi-ul {
  @apply list-none w-full;
}

li.pmi-li {
  @apply flex flex-row items-stretch;
}

// Style the content on the left
.pmi-li--left-content {
  @apply mr-sm flex items-center;
}

// Style the wrapper of the main content and the right content.
// This wrapper is reponsible for the bottom border that separates items in
// the list.
.pmi-li--content-wrapper {
  @apply flex flex-row w-full py-xs items-stretch;
}

// Style the main content
.pmi-li--main-content {
  @apply w-full self-center;
}
.pmi-li--main-content > button {
  @apply text-left;
}

// Style the content on the right
.pmi-li--right-content {
  @apply ml-sm flex items-center;
}
