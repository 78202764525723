.profile-details--call-button {
  @apply flex flex-row items-center w-fit gap-xs transition-all px-sm py-xs -mx-sm -my-xs;
}

.profile-details--email-button {
  @apply flex flex-row items-center w-fit gap-xs transition-all px-sm py-xs -mx-sm -my-xs;
}

// Target devices that can hover
@media (hover: hover) {
  .profile-details--call-button {
    @apply hover:text-active hover:underline;
  }

  .profile-details--email-button {
    @apply hover:text-active hover:underline;
  }
}

// Target deices that cannot hover
@media (hover: none) {
  .profile-details--call-button {
    @apply text-active underline;
  }

  .profile-details--email-button {
    @apply text-active underline;
  }
}
