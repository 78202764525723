a.component__account-child-navigation-item {
  @apply grow
          p-sm
          relative
          transition-all
          bg-white
          first-of-type:rounded-t-md
          last-of-type:rounded-b-md
          rounded-none
          md:rounded-md
          shadow-md;
}

@media (hover: hover) {
  a.component__account-child-navigation-item {
    @apply hover:text-active
            hover:bg-secondary;
  }
}
