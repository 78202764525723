.component__helper-modal {
  @apply overflow-auto fixed top-0 left-0 hidden w-full h-full opacity-0;
  @apply flex flex-col justify-start items-center opacity-100 z-30;
  background: rgba(0, 0, 0, 0.48);
  backdrop-filter: blur(20px);

  .modal-overlay {
    @apply w-full flex flex-col relative box-border z-1 pointer-events-none;
    background: white;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 64px;
    padding-bottom: 16px;
    clip-path: inset(64px 16px 16px 16px round 25px);
  }

  .modal-content-wrapper {
    @apply visible
          pointer-events-auto
          order-2
          p-sm
          md:p-md;
  }

  .modal-close-btn-container {
    @apply flex justify-end;

    button.modal-close-btn {
      @apply text-silver bg-lightGray rounded-full p-xxs transition-all;
    }
  }
}

@media (hover: hover) {
  .component__helper-modal {
    .modal-close-btn-container button.modal-close-btn:hover {
      @apply bg-silver text-white shadow-md;
    }
  }
}
