button.component__widget-details-button {
  @apply flex
        flex-row
        items-center
        gap-1
        rounded-md
        p-xxs
        -m-xxs;
}

@media (hover: hover) {
  button.component__widget-details-button:hover {
    @apply shadow-md text-primary bg-secondary;
  }
}

@media (hover: none) {
  button.component__widget-details-button {
    @apply text-primary underline;
  }
}
