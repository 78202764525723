.timeline-container {
  @apply relative;
}

.timeline::before {
  @apply absolute rounded-full content-empty;
  width: 2px;
  // The circles are 16px wide. The line is 2px wide. 7px ensure the line is centered
  // in the circles
  left: 7px;
  // The circles are down 4 pixels, we need to ensure the line is too
  top: 4px;
  // And remove those 4 pixels from the total height to avoid drawing out of
  // the container
  height: calc(100% - 4px);
  background-color: var(--lightBlue);
}

.timeline--item-container {
  // Give space to place the circle
  margin-left: 32px;

  // Circle
  &::before {
    @apply absolute rounded-full;
    content: '';
    width: 16px;
    height: 16px;
    left: 0;
    // The circles are down 4 pixels, we need to ensure the line is too
    top: 4px;
    background-color: white;
    border: 2px solid var(--lightBlue);
  }
}
