ol.component__whats-new-content {
  @apply bg-white shadow-md rounded-md p-sm md:p-lg;

  li.component__whats-new-content--item {
    @apply relative pb-xl;
  }

  li.component__whats-new-content--item::before {
    @apply absolute h-full hidden md:block;
    background-color: #ebebeb;
    content: '';
    left: 8px;
    top: 0;
    transform: none;
    width: 2px;
  }
}
